import React from 'react';
import 'whatwg-fetch';
import i18n from '../../locale';
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import Back from './Back';
import Services from './Services';
import Contact from './Contact';
import Done from './Done';
import ErrorMessage from './ErrorMessage';
import styles from './styles.module.sass';
import sitemap from '../../data/sitemap';
import StepIndex from './StepIndex';

const postToForm = data => fetch(
'https://api.hsforms.com/submissions/v3/integration/submit/5142926/856c0f88-d86c-464d-80c1-a3bf3727f269',
{
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    'submittedAt': Date.now(),
    'fields': [
      {
        'name' : 'email',
        'value': data.email
      }, {
        'name' : 'phone',
        'value': data.phone ? data.phone : ''
      }, {
        'name': 'firstname',
        'value': data.name ? data.name : ''
      }, {
        'name' : 'services_in',
        'value': Object.keys(data['services'])
          .filter(k => data['services'][k])
          .join(';')
      }
    ],
    'context': {
      'pageUri': i18n('_url:_') + i18n('_url:sign-up'),
      'pageName': 'Sign up'
    },
    'legalConsentOptions': {
      "consent": {
        "consentToProcess": true,
        "text": "I agree to allow OBI+ to store and process my personal data.",
        "communications": [
          {
            "value": true,
            "subscriptionTypeId": 999,
            "text": "I agree to receive marketing communications from OBI+."
          }
        ]
      }
    }
  })
})

export default ({ seo, location }) => {
  const [ error, setError ] = React.useState(false);
  const [ step, setStep ] = React.useState(0);
  const [ formData, setFormData ] = React.useState({
    'services': {
      'Fleet': false,
      'Vehicle Care': false,
      'Driver App': false
    }});
  const onNext = () => setStep(step + 1);
  const setFormKey = key => data => setFormData({
    ...formData,
    [key]: data
  });

  return (
    <Layout overlay page={sitemap.signUp}
      url='_url:sign-up'>
      <SEO {...seo} location={location}/>
      <div className={styles.container}>
        <StepIndex step={step}/>
        <Back onPress={step > 0 && step < 4
          ? () => {
              setStep(step - 1);
              setError(false);
            }
          : null}/>
        {step === 0
        ? <Services
            formData={formData['services']}
            setFormData={setFormKey('services')}
            onNext={onNext}/>
        : step === 1
        ? <Contact
            email={formData['email']}
            phone={formData['phone']}
            name={formData['name']}
            setEmail={setFormKey('email')}
            setPhone={setFormKey('phone')}
            setName={setFormKey('name')}
            onNext={() => postToForm(formData)
              .then(res => res.json()).then(res => {
                if(res.status !== 'error') {
                  setStep(step + 1);
                  setError(false);
                } else setError(true);
              }).catch(() => setError(true))}/>
        : <Done/>}
        <ErrorMessage error={error}/>
      </div>
    </Layout>
  )
}