import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import QuestionTitle from '../QuestionTitle';
import Next from '../Next';
import template from './template';
import styles from './styles.module.sass';

const Option = ({
  text,
  subtext,
  Icon,
  formData,
  value,
  selected = !!formData[value],
  setFormData,
  i
}) => (
  <div className={clsx(
      styles.optionWrapper,
      selected ? styles.optionWrapperSelected : ''
    )}>
    <div className={styles.optionContainer}
      onClick={() => {
        if(selected) setFormData({
          ...formData,
          [value]: false
        });
        else setFormData({
          ...formData,
          [value]: true
        });
      }}
      onKeyDown={() => {
        if(selected) setFormData({
          ...formData,
          [value]: false
        });
        else setFormData({
          ...formData,
          [value]: true
        });
      }}
      role={'button'}
      tabIndex={i}>
      <div className={styles.iconContainer}>
        <Icon/>
      </div>
      <Typography variant='subtitle2'
        weight='bold'
        className={styles.iconText}>
        {text}
      </Typography>
      <Typography variant='subtitle2'
        weight='light'
        className={styles.iconSubText}>
        {subtext}
      </Typography>
    </div>
  </div>
);

export default ({ setFormData, formData, onNext }) => (
  <>
    <QuestionTitle text={`${i18n('Choose which of the services you want to see in action')}`}
      subtext={`${i18n('You can choose 1 or more of the services below.')}`}/>
    <Grid container className={styles.container} justify='center'>
      {template.map((t, i) => (
        <Option key={t.value}
          formData={formData}
          setFormData={setFormData}
          i={i}
          {...t}/>
      ))}
    </Grid>
    <Next onClick={onNext}
      disabled={!(
        formData['Fleet']
        || formData['Vehicle Care']
        || formData['Driver App']
      )}/>
  </>
)